import { FunctionComponent } from 'react';

const BodyWithStyle: FunctionComponent<{}> = () => {
    return (
        <style jsx global>{`
            body {
                background-color: var(--bg-1);
            }
        `}</style>
    );
};

export default BodyWithStyle;
