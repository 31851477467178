import classNames from 'classnames';
import Head from 'next/head';
import { FunctionComponent, PropsWithChildren } from 'react';
import BodyWithStyle from '../../components/BodyWithStyle';
import { MarketplaceShellHeader } from '../../app/components/MarketplaceShell/Header';

export type MinimalLayoutProps = PropsWithChildren & {
    title: string;
    description: string | null;
    pageClassName?: string;
    themeColor?: string;
};

const MinimalLayout: FunctionComponent<MinimalLayoutProps> = ({
    title,
    pageClassName,
    children,
    description = null,
    themeColor = '#f9f9fb',
}) => {
    return (
        <>
            <Head>
                <title>{title}</title>
                {description ? <meta name="description" content={description} /> : null}
                {description ? <meta name="og:description" content={description} /> : null}
                <meta name="theme-color" content={themeColor} />
                <link rel="icon" href={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/favicon.ico`} />
                <link
                    rel="apple-touch-icon"
                    href={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/logo192.png`}
                />
                <link rel="manifest" href="/manifest.json" />
            </Head>

            <BodyWithStyle />

            <MarketplaceShellHeader showSearchBar={false} />

            <div className={classNames(pageClassName)}>{children}</div>
        </>
    );
};

export default MinimalLayout;
